html {
  font-family: "Open Sans", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ribbon-footer:before {
  border-right: 10px solid #cb6015;
  border-top: 20px solid transparent;
  content: "";
  display: block;
  height: 0;
  right: 100%;
  position: absolute;
  top: 0;
  width: 7px;
}
.MuiCard-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  opacity: 0.6;
}
html {
  position: relative;
  min-height: 100%;

}
body {
  margin-bottom: 8rem;
}

footer {
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  background-color: #f5f5f5;
}

.gradiant-bg-primary-dark {
  background: rgb(255, 80, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 80, 0, 1) 0%,
    rgba(204, 65, 0, 1) 100%
  );
}

#header-sticky {
  position: sticky;
  top: 0;
  z-index: 1100;
}

/*Caroucel arrow*/
.slick-track{
  float:left;

}